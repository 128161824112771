@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&family=Syne+Mono&family=Syne:wght@500&display=swap');

body {
  margin: 0;
}

#root,
body,
html {
  height: 100%;
}

* {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}